<template>
    <div class="QuickTest full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <navigation-left-small noBackButton TestBack disabled/>
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('quickTest.selectBatch') }}</p>
                        </v-col>
                        <v-col class="col-4">
                            <v-select :items="batchNames" item-text="name" item-value="id" v-model="quickTest.batch"
                                      class="quickTest-batch-dropdown" outlined hide-details="auto" attach
                                      :rules="[v => v != null || $t('validation.required')]"/>
                        </v-col>
                        <v-col class="col-2 pl-4" :class="{'disabled': newBatch}">
                            <p>{{ $t('quickTest.nameNewBatch') }}</p>
                        </v-col>
                        <v-col class="col-4">
                            <text-input inputName="quickTest-newBatch" @onInputFocus="onInputFocus" :disabled="newBatch"
                                        :rules="[rules.maxLengthName, rules.alphanumericSpecCharValue, rules.batchname]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('quickTest.testingTime') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-dialog ref="testingTimeDialog" v-model="testingTimeModal"
                                      :return-value.sync="quickTest.testingTime" persistent width="350px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="quickTest.testingTime"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details="auto"
                                        outlined
                                        class="is-modal"
                                        :rules="[rules.testingTime]"
                                        id="testingTimeModal"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="quickTest.testingTime"
                                    scrollable
                                    header-color="disiDarkblue"
                                    width="350"
                                    format="24hr"
                                    use-seconds
                                    id="testingTimeModal-timepicker"
                                >
                                    <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2"
                                           @click="testingTimeModal = false;">{{ $t('footerButton.cancel') }}
                                    </v-btn>
                                    <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2"
                                           @click="$refs.testingTimeDialog.save(quickTest.testingTime)">
                                        {{ $t('footerButton.save') }}
                                    </v-btn>
                                </v-time-picker>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('quickTest.selectMedia') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-select :items="activeMedia" item-text="name" item-value="id" v-model="quickTest.media"
                                      class="quickTest-media-dropdown" outlined hide-details="auto" attach/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('quickTest.selectBasket') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-select :items="selectBasket" item-text="name" item-value="value"
                                      v-model="quickTest.basket" class="quickTest-basket-dropdown" outlined
                                      hide-details="auto" attach :rules="[v => !!v || $t('validation.required')]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="quickTest-combinationTest" :disabled="quickTest.basket != 3"
                                           :class="{'active': combinationTestStatus}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-10" :class="{'disabled': quickTest.basket != 3}">
                            <p>{{ $t('quickTest.combinationTest') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('quickTest.thresholdHeight') }}</p>
                        </v-col>
                        <v-col class="col-4">
                            <text-input inputName="quickTest-threshold" @onInputFocus="onInputFocus"
                                        suffix="mm" onlyNumbers :rules="[rules.threshold, rules.maxDecimalPlaces1]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="quickTest-temperatureStatus" :class="{'active': temperatureStatus}"
                                           class="mr-4"/>
                        </v-col>
                        <v-col class="col-4">
                            <p>{{ $t('quickTest.temperatureLimits') }}</p>
                        </v-col>
                        <v-col class="col-1 text-right" :class="{'disabled': !temperatureStatus}">
                            <p>{{ $t('general.from') }}</p>
                        </v-col>
                        <v-col class="col-2">
                            <text-input inputName="quickTest-temperatureMin" @onInputFocus="onInputFocus"
                                        :disabled="!temperatureStatus" suffix="°C" onlyNumbers
                                        :rules="[rules.temperatureMin, rules.maxDecimalPlaces1]"/>
                        </v-col>
                        <v-col class="col-1 text-center" :class="{'disabled': !temperatureStatus}">
                            <p>{{ $t('general.to') }}</p>
                        </v-col>
                        <v-col class="col-2">
                            <text-input inputName="quickTest-temperatureMax" @onInputFocus="onInputFocus"
                                        :disabled="!temperatureStatus" suffix="°C" onlyNumbers
                                        :rules="[rules.temperatureMax, rules.maxDecimalPlaces1]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="quickTest-fastDisintegration" :disabled="withoutDisc || manual"
                                           :class="{'active': fastDisintegration}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-6" :class="{'disabled': withoutDisc || manual}">
                            <p>{{ $t('quickTest.fastDisintegration') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="quickTest-withoutDisc" :class="{'active': withoutDisc}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-6">
                            <p>{{ $t('quickTest.withoutDisc') }}</p>
                        </v-col>
                    </v-row>
<!--                    <v-row class="d-flex align-center">-->
<!--                        <v-col class="col-2">-->
<!--                            <switch-on-off name="quickTest-manual" :class="{'active': manual}" class="mr-4"/>-->
<!--                        </v-col>-->
<!--                        <v-col class="col-6">-->
<!--                            <p>{{ $t('quickTest.manual') }}</p>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToDetailPage"></footer-button>
            <footer-button buttonFunction="start" @footerButtonClick="startQuickTest"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import NavigationLeftSmall from '@/components/NavigationLeftSmall.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import TextInput from '@/components/TextInput.vue'
import FooterButton from '@/components/FooterButton.vue'
import {mapGetters, mapState} from 'vuex'
import mixins from "@/mixins/mixins";

export default {
    name: 'QuickTest',
    components: {
        NavigationLeftSmall,
        SwitchOnOff,
        TextInput,
        FooterButton,
    },
    props: {},
    data() {
        return {
            selectBasket: [
                {
                    name: `3 ${this.$t('general.tubes')}`,
                    value: 3
                },
                {
                    name: `6 ${this.$t('general.tubes')}`,
                    value: 6
                }
            ],
            testingTimeModal: false,
            rules: {
                maxLengthName: v => {
                    if (v) {
                        return v.length < 24 || `${this.$t('validation.chooseShorterName')}`;
                    } else return true;
                },
                alphanumericSpecCharValue: v => {
                    if (v) {
                        let alphanumericSpecChar = /^[A-Za-z0-9*%&\-_/+?!@#.]+$/;
                        return alphanumericSpecChar.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                },
                batchname: v => {
                    if (this.quickTest.batch === 0) {
                        if (v) {
                            return !!v;
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
                testingTime: v => {
                    if (v && v !== '00:00:00') {
                        return !!v;
                    } else {
                        return `${this.$t('validation.required')}`;
                    }
                },
                threshold: v => {
                    if (v) {
                        return (parseFloat(v) >= 0.0 && parseFloat(v) <= 2.0) || `${this.$t('validation.minimum')}: 0.0mmtest, ${this.$t('validation.maximum')}: 2.0mm`;
                    } else return true;
                },
                temperatureMin: v => {
                    if (this.quickTest.temperatureStatus) {
                        if (v) {
                            return (parseFloat(v) >= 0.0 && parseFloat(v) <= 40.0) || `${this.$t('validation.minimum')}: 0.0°C, ${this.$t('validation.maximum')}: 40°C`;
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
                temperatureMax: v => {
                    if (this.quickTest.temperatureStatus) {
                        if (v) {
                            if (this.quickTest.temperatureMin) {
                                return (parseFloat(v) >= parseFloat(this.quickTest.temperatureMin) && parseFloat(v) <= 40.0) || `${this.$t('validation.minimum')}: ${this.quickTest.temperatureMin}°C, ${this.$t('validation.maximum')}: 40°C`;
                            } else {
                                return (parseFloat(v) >= 0.0 && parseFloat(v) <= 40.0) || `${this.$t('validation.minimum')}: 0.0°C, ${this.$t('validation.maximum')}: 40°C`;
                            }
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
                maxDecimalPlaces1: v => {
                    if (v) {
                        let test = v.toString().split('.');
                        if (test[1]) {
                            if (test[1].length > 1) {
                                return `${this.$t('validation.chooseShorterDecimalPlace')}`;
                            }
                        }
                        return true;
                    } else return true;
                }
            }
        }
    },
    computed: {
        ...mapState([
            'activeStationId',
            'quickTest',
            'medialist',
            'batchlist'
        ]),
        ...mapGetters({
            activeMedia: 'activeMedia',
        }),
        batchNames() {
            let batchNames = [];
            let newBatch = {
                id: 0,
                name: this.$t('quickTest.newBatch')
            }
            batchNames.push(newBatch);
            this.batchlist.forEach(batch => {
                batchNames.push(batch);
            })
            return batchNames;
        },
        newBatch() {
            if (this.quickTest.batch != 0 || this.quickTest.batch == null) {
                return true;
            } else {
                return false;
            }
        },
        combinationTestStatus() {
            return this.$store.state.quickTest.combinationTest;
        },
        temperatureStatus() {
            return this.$store.state.quickTest.temperatureStatus;
        },
        fastDisintegration() {
            return this.$store.state.quickTest.fastDisintegration;
        },
        withoutDisc() {
            return this.$store.state.quickTest.withoutDisc;
        },
        manual() {
            return this.$store.state.quickTest.manual;
        },
    },
    methods: {
        goToDetailPage() {
            this.$router.push('station-testdetail');
        },
        startQuickTest() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.$confirm({message: this.$t('alerts.securityInfo'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('footerButton.start') })
                    .then(() => {
                        // continue
                        let newQuickTest = JSON.parse(JSON.stringify(this.quickTest));
                        newQuickTest.station = this.activeStationId;
                        newQuickTest.testingTime = this.timestampFormat(newQuickTest.testingTime);
                        if (this.quickTest.threshold === '') {
                            newQuickTest.threshold = 0;
                        } else {
                            newQuickTest.threshold = parseFloat(newQuickTest.threshold);
                        }
                        newQuickTest.temperatureMin = parseFloat(newQuickTest.temperatureMin);
                        newQuickTest.temperatureMax = parseFloat(newQuickTest.temperatureMax);
                        this.$store.dispatch('postAxiosNoSetter', ['disi/testing/quick-test', newQuickTest])
                            .then(response => {
                                if (response.status === 200) {
                                    this.goToDetailPage();
                                }
                            });
                    })
                    .catch(() => {
                        // cancel
                        this.$confirm({message: this.$t('alerts.securityInfo'), show: false, cancelText: this.$t('general.cancel'), continueText: this.$t('footerButton.start') })
                    });
            } else {
                //scroll to error
                setTimeout(() => {
                    let message = document.querySelector('.v-input.error--text');
                    if (message) {
                        let messagePosition = message.getBoundingClientRect();
                        document.querySelector('.content-area').scrollTo({
                            top: messagePosition.top,
                            behavior: 'smooth'
                        });
                    } else {
                        document.querySelector('.content-area').scrollTo({top: 0, behavior: 'smooth'});
                    }
                }, 100)
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#' + input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('postAxiosSetter', ['/disi/testing/batch/list', {'quickTest': true}, 'batchlist']);
        this.$store.dispatch('getAxiosSetter', ['/disi/media/list', 'medialist']);
        this.$store.commit('resetQuickTest');

        //set combi test off if 6 tubes is selected
        this.$watch('$store.state.quickTest.basket', function () {
            if (this.$store.state.quickTest.basket == '6') {
                this.$store.commit('setCombiTestOff', 'quickTest');
            }
        });

        //set fast disintigration off if manual is on
        this.$watch('$store.state.quickTest.manual', function () {
            if (this.$store.state.quickTest.manual) {
                this.$store.commit('setFastDisintigrationOff', 'quickTest');
            }
        });

        //set fast disintigration off if without disc is on
        this.$watch('$store.state.quickTest.withoutDisc', function () {
            if (this.$store.state.quickTest.withoutDisc) {
                this.$store.commit('setFastDisintigrationOff', 'quickTest');
            }
        });
    },
}
</script>
<style scoped lang="scss">

</style>